import React, { useContext, useState, useMemo, useEffect, useRef } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import {
  Row,
  Input,
  Label,
  Col,
  Button,
  Badge,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import QRCode from "react-qr-code";
import Header from "../../layout/Header/Header";
import Baja from "../Baja";
import moment from "moment";

function Dashboard() {
  const { user } = useContext(AuthContext);
  const URL_INFO = process.env.REACT_APP_URL_INFO;
  const URL_POSICIONES = process.env.REACT_APP_URL_POSICIONES;
  const URL_ORDENES = process.env.REACT_APP_URL_ORDENES;
  const URL_SALDO_USERS = process.env.REACT_APP_URL_SALDO_USERS;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 30);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [actualizado, setActualizado] = useState(false);
  const [ordenes, setOrdenes] = useState([]);
  const [posiciones, setPosiciones] = useState([]);
  const [markPrice, setMarkPrice] = useState(0);
  const [saldo, setSaldo] = useState([]);

  const [fechaInicio, setFechaInicio] = useState(startDate);
  const [fechaFin, setFechaFin] = useState(endDate);

  const widgetRef = useRef(null);

  useMemo(() => {
    if (user.id) {
      axios
        .get(`${URL_INFO}GetOpenOrders/${user.id}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          setActualizado(true);
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .get(
          `${URL_SALDO_USERS}UserFechas/${user.id}/${fechaInicio}/${fechaFin}`,
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          let allSaldo = response.data;
          setSaldo(allSaldo);
        })
        .catch((err) => {
          console.log(err);
        });

      if (actualizado) {
        axios
          .get(`${URL_POSICIONES}User/${user.id}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((response) => {
            let allPosiciones = response.data;
            setMarkPrice(allPosiciones[0].markPrice);
            setPosiciones(allPosiciones);
          })
          .catch((err) => {
            console.log(err);
          });

        axios
          .get(`${URL_ORDENES}User/${user.id}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((response) => {
            let allOrdenes = response.data;
            setOrdenes(allOrdenes);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [actualizado, user]);

  useEffect(() => {
    if (!document.getElementById("tradingview_script")) {
      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/tv.js";
      script.id = "tradingview_script";
      script.async = true;
      script.onload = () => {
        if (window.TradingView) {
          new window.TradingView.widget({
            container_id: "tradingview_chart",
            width: "100%",
            height: 400,
            symbol: "BINANCE:BTCUSDT.P",
            interval: "15",
            timezone: "America/Mexico_City",
            theme: "light",
            style: "1",
            locale: "es",
            toolbar_bg: "#f1f3f6",
            enable_publishing: false,
            allow_symbol_change: true,
            hide_side_toolbar: false,
          });
        }
      };
      document.body.appendChild(script);
    }
  }, []);

  async function Actualizar() {
    setActualizado(false);
    axios
      .get(`${URL_INFO}GetOpenOrders/${user.id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        setActualizado(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function Cancel(orderId) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se dara de baja!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, dar de Baja!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(`${URL_ORDENES}Cancel/${orderId}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((response) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Se dio de Baja",
              showConfirmButton: false,
              timer: 2000,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  const renderGraficaSaldo = () => (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={saldo} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="fecha" />
        <YAxis scale="log" domain={['dataMin', 'dataMax']} />

        <Tooltip />
        <Line type="monotone" dataKey="saldo" stroke="#8884d8" />
      </LineChart>
    </ResponsiveContainer>
  );
  

  return (
    <>
      <Header />
      <br />
      <br />
      {user.usuarios_permisos ? (
        <div className="card container col-sm-10">
          {/* <div className="row"> */}
          <h3 align="center">Dashboard</h3>
          <Row>
            <Col md={8}>
              <h3>
                Precio Actual:{" "}
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(markPrice)}
              </h3>
            </Col>
            <Col md={2}>
              <h4 align="right">
                <Badge
                  color={actualizado ? "success" : "danger"}
                  className="BadgeSize badge-pill"
                  onClick={Actualizar}
                >
                  {actualizado ? "Sync " : "NO Sync "}
                  <i class="fas fa-sync-alt" onClick={Actualizar}></i>
                </Badge>
              </h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Posiciones</h3> {/* Añade margen superior al título */}
            </Col>
          </Row>

          <Table striped borderless className="table-responsive-xl">
            <thead>
              <tr>
                {/* <th>Symbol</th> */}
                <th>Cantidad</th>
                <th>Precio de Entrada</th>
                <th>BreakEven</th>
                {/* <th>Precio Actual</th> */}
                <th>P&L</th>
              </tr>
            </thead>
            {posiciones.length === 0 ? (
              <tr>
                <td colSpan="6">
                  <h3 align="center">No hay posiciones abiertas</h3>
                </td>
              </tr>
            ) : (
              <tbody>
                {posiciones.map((a) => (
                  <tr>
                    {/* <td>{a.symbol}</td> */}
                    <td>
                      {/* {a.positionAmt} BTC /{" "} */}
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2,
                      }).format((a.positionAmt * a.markPrice) / a.leverage)}
                      {/* {" "}USDT */}
                    </td>
                    <td>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2,
                      }).format(a.entryPrice)}
                    </td>
                    <td>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2,
                      }).format(a.breakEvenPrice)}
                    </td>
                    {/* <td>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 2,
                    }).format(a.markPrice)}
                  </td> */}
                    <td>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2,
                      }).format(a.unRealizedProfit)}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </Table>

          <Row>
            <Col>
              <h3>Ordenes Pendientes</h3>{" "}
              {/* Añade margen superior al título */}
            </Col>
          </Row>

          <Table striped borderless className="table-responsive-xl">
            <thead>
              <tr>
                {/* <th>Symbol</th> */}
                <th>Cantidad</th>
                <th>Precio</th>
                <th>Tipo</th>
                <th>Cancelar</th>
              </tr>
            </thead>
            {ordenes.length === 0 ? (
              <tr>
                <td colSpan="6">
                  <h3 align="center">No hay ordenes abiertas</h3>
                </td>
              </tr>
            ) : (
              <tbody>
                {ordenes
                  .sort((a, b) => (a.origQty > b.origQty ? 1 : -1))
                  .map((a) => (
                    <tr>
                      {/* <td>{a.symbol}</td> */}
                      <td>
                        {/* {a.origQty} BTC /{" "} */}
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 2,
                        }).format((a.origQty * a.price) / 25)}
                        {/* {" "}USDT */}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 2,
                        }).format(a.price)}
                      </td>
                      <td>{a.reduceOnly ? "TP" : "Entrada"}</td>
                      <td>
                        {/* <Button
                    color="danger"
                    id="Baja"
                    onClick={e=> Cancel(a.orderId)}
                    size="sm"
                  >
                    <i class="far fa-times-circle"></i>
                  </Button> */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
          </Table>
          {/* </div> */}

          <div>
            <h3>Gráfica de Bitcoin</h3>
            <div ref={widgetRef} id="tradingview_chart" />
          </div>
          <br />
          <br />
          <div className="grafica-saldo-container">
          <h3>Incremento en el Saldo</h3>
          {renderGraficaSaldo()}
        </div>

          <br />
        </div>
      ) : undefined}
    </>
  );
}

export default Dashboard;
