import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
// import Signup from "./Signup";
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Col,
  Badge
} from "reactstrap";
import Header from "../../layout/Header/Header";
import { TableHeader, Pagination, Search } from "../../DataTable";
import useFullPageLoader from "../../hooks/useFullPageLoader";
// import Baja from "../../views/Baja";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function Config() {
  const { user } = useContext(AuthContext);
  const URL_CONFIGS = process.env.REACT_APP_URL_CONFIGS;

  const [idConfig, setIdConfig] = useState("");
  const [isActive, setIsActive] = useState("");
  const [sistema, setSistema] = useState("");
  const [long, setLong] = useState("");
  const [short, setShort] = useState("");
  
  useEffect(() => {
    axios
    .get(URL_CONFIGS, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((res) => {
      const allConfig = res.data;
      setIsActive(allConfig[0].is_active);
      setSistema(allConfig[0].sistema);
      setLong(allConfig[0].long);
      setShort(allConfig[0].short);
      setIdConfig(allConfig[0]._id)
    })
    .catch((err) => {
      console.log(err);
    });
  }, []);

  
  async function Actualizar(variable, valorActual) {
    Swal.fire({
      title: "¿Estás seguro?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Sí!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Alterna el valor de la variable
        let nuevoValor = valorActual === "Activo" ? "Apagado" : "Activo";
  
        axios
          .patch(
            `${URL_CONFIGS}/${idConfig}`,
            {
              [variable]: nuevoValor,  // Actualiza la propiedad específica
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            Swal.fire("¡Buen trabajo!", "Actualizado con éxito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "¡Algo salió mal!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }
  

  return (
    <>
      <Header />
      <br />
      {user.menu_usuarios ? (
        <div className="row">
          {
            <div className="col-8 card container">
                <Row>
                  <Col md={6}>
                  <h4 align="right">Activo:</h4>
                  </Col>
                  <Col md={2}>
                    <h4 align="right">
                      <Badge
                        color={isActive == "Si" ? "success" : "danger"}
                        className="BadgeSize badge-pill"
                        onClick={() => Actualizar("is_active", isActive)}
                      >
                        {isActive == "Si" ? "Activo" : "No Activo"}
                      
                      </Badge>
                    </h4>
                  </Col>
                </Row> 

                <Row>
                  <Col md={6}>
                  <h4 align="right">Sistema:</h4>
                  </Col>
                  <Col md={2}>
                    <h4 align="right">
                      <Badge
                        color={sistema == "Activo" ? "success" : "danger"}
                        className="BadgeSize badge-pill"
                        onClick={() => Actualizar("sistema", sistema)}
                      >
                        {sistema == "Activo" ? "Activo" : "Apagado"}                      
                      </Badge>
                    </h4>
                  </Col>
                </Row> 

                <Row>
                  <Col md={6}>
                  <h4 align="right">Long:</h4>
                  </Col>
                  <Col md={2}>
                    <h4 align="right">
                      <Badge
                        color={long == "Activo" ? "success" : "danger"}
                        className="BadgeSize badge-pill"
                        onClick={() => Actualizar("long", long)}
                      >
                        {long == "Activo" ? "Activo" : "Apagado"}                      
                      </Badge>
                    </h4>
                  </Col>
                </Row> 

                <Row>
                  <Col md={6}>
                  <h4 align="right">Short:</h4>
                  </Col>
                  <Col md={2}>
                    <h4 align="right">
                      <Badge
                        color={short == "Activo" ? "success" : "danger"}
                        className="BadgeSize badge-pill"
                        onClick={() => Actualizar("short", short)}
                      >
                        {short == "Activo" ? "Activo" : "Apagado"}                      
                      </Badge>
                    </h4>
                  </Col>
                </Row> 
            </div>
          }
        </div>
          ) : undefined}
    </>
  );
}

export default Config;