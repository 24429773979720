import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { TableHeader, Pagination, Search } from "../../DataTable";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import axios from "axios";
import {
  Table,
  Button,
  Col,
  Row,
  Label,
  Input,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import Header from "../../layout/Header/Header";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";

function ListadoCargos() {
  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 30);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CARGOS = process.env.REACT_APP_URL_CARGOS;
  const URL_USERS = process.env.REACT_APP_URL_USERS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  const [cargos, setCargos] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersEdit, setUsersEdit] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");
  const [value, setValue] = useState();
  const [valueEdit, setValueEdit] = useState();
  const [validaBoton, setValidaBoton] = useState(true);

  const [text, setText] = useState(false);

  const [selectedUserEdit, setSelectedUserEdit] = useState("");
  const [fecha, setFecha] = useState("");
  const [importe, setImporte] = useState("");
  const [importeViejo, setImporteViejo] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [IdEditCargo, setIdEditCargo] = useState("");
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  // function jalaFechas (){

  //   const Toast = Swal.mixin({
  //     toast: true,
  //     position: 'center',
  //     showConfirmButton: false,
  //     timer: 4000,
  //     timerProgressBar: true,
  //     didOpen: (toast) => {
  //       toast.addEventListener('mouseenter', Swal.stopTimer)
  //       toast.addEventListener('mouseleave', Swal.resumeTimer)
  //     }
  //   })

  //   Toast.fire({
  //     icon: 'success',
  //     title: 'Danos unos segundos....'
  //   })

  // }

  useMemo(() => {
    axios
      .get(`${URL_CARGOS}/fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCargos = response.data;
        let arrayTabla = allCargos
          .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              fecha: a.fecha,
              user: a.user[0].nombre,
              idUser: a.user[0]._id,
              importe: a.importe,
              descripcion: a.descripcion,
              numero: a.idCargo
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal);
        setCargos(allCargos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_USERS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allUsers = res.data;
        setUsers(allUsers);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);
  let total = 0;

  function PDFTabla() {
    const logo = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    total = 0;
    const data = cargos.map((c) => {
      if (
        (selectedUser == 0 || selectedUser == c.user[0]._id) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha)
      ) {
        total = total + c.importe;
        return [
          c.fecha,
          [c.user[0].nombre],
          "$" + new Intl.NumberFormat("en-US").format(c.importe),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.addImage(img, "png", 230, 15, 45, 15);
    doc.text("Listado de Cargos", 15, 25);
    doc.autoTable({
      head: [["Fecha", "User", "Importe"]],
      body: dataPDFLimpia,
      startY: 30,
      foot: [["", "Total", "$" + new Intl.NumberFormat("en-US").format(total)]],
      showFoot: "lastPage",
    });
    doc.save("ListadoCargos.pdf");
  }

  function excel() {
    const dataExcel = cargos.map((c) => {
      if (
        (selectedUser == 0 || selectedUser == c.user[0]._id) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha)
      ) {
        return {
          Fecha: c.fecha,
          User: c.user[0].nombre,
          Importe: c.importe,
        };
      }
    });

    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoCargos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoCargos",
        sheetFilter: ["Fecha", "User", "Importe"],
        sheetHeader: ["Fecha", "User", "Importe"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const logo = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    total = 0;
    const data = cargos.map((c) => {
      if (
        (selectedUser == 0 || selectedUser == c.user[0]._id) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha)
      ) {
        total = total + c.importe;
        return [
          c.fecha,
          c.user[0].nombre,
          "$" + new Intl.NumberFormat("en-US").format(c.importe),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 15, 45, 15);
    doc.text("Listado de Cargos", 20, 25);
    doc.autoTable({
      head: [["Fecha", "User", "Importe"]],
      body: dataPDFLimpia,
      startY: 30,
      foot: [["", "Total", "$" + new Intl.NumberFormat("en-US").format(total)]],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Cargos",
          email: mailTo,
          fileName: "Listado-Cargos.pdf",
          att: base64File,
          text: `<!DOCTYPE html>
         <html>
           <head>
             <style>                                
               .img-container {
                               display: block;
                               margin-left: auto;
                               margin-right: auto;
                               height: 90px;
                               width: auto;
                             }
             </style>
           </head>
           <body>
             <img class="img-container" alt="Logo" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" />              
             <h1>${process.env.REACT_APP_NOMBRE_INSTITUCION}</h1>
             <h3>Hola,</h3>
             <h3>Adjunto encontraras el listado de cargos.</h3>
             <h4>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</h4>
             Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> 
           </body>
         </html>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function editCargo(event) {
    event.preventDefault();
    setValidaBoton(false);
    const URL_CARGOS_EDIT = `${process.env.REACT_APP_URL_CARGOS}/${IdEditCargo}`;
    axios
      .patch(
        URL_CARGOS_EDIT,
        {
          fecha,
          descripcion,
          importe,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        // Actualizamos user
        axios
          .patch(
            `${URL_CARGOS}CargosEdit/${selectedUserEdit}`,
            {
              cargoViejo: importeViejo,
              cargoNuevo: importe,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            axios.post(
              URL_LOGS,
              {
                tipo: "Editar Cargo Cargo",
                listado: `${selectedUserEdit}`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
            toggleEdit();
            Swal.fire("Good job!", "Creado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });

        // Termina actualizar user
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(idEdit, user) {
    setUsersEdit(user)
    const URL_CARGOS_EDIT = `${process.env.REACT_APP_URL_CARGOS}/${idEdit}`;
    axios
      .get(URL_CARGOS_EDIT, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        setFecha(response.data.fecha);
        setDescripcion(response.data.descripcion);
        setImporte(response.data.importe);
        setImporteViejo(response.data.importe);
        setSelectedUserEdit(response.data.user[0]._id);
        setIdEditCargo(idEdit);
        toggleEdit();
      })
      .catch((err) => {
        console.log(err);
      });
  }


  const headers = [
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "User", field: "user", sortable: true },
    { name: "Importe", field: "importe", sortable: false },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter((comment) =>
        comment.user.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (selectedUser) {
      computedComments = computedComments.filter((e) =>
        e.idUser.includes(selectedUser)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [comments, currentPage, search, sorting, selectedUser]);

  async function PDFOC(
    fecha,
    users,
    importe,
    numero
  ) {
        const doc = new jsPDF();
        var img = new Image();
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img2, "png", 67, 15, 70, 20);
        doc.setFontSize(16);
        doc.text(`Cargo`,100,40);
        doc.autoTable({
          head: [
            [
              "Fecha",
              "User",
              "Importe",
            ],
          ],
          body: [[
            fecha,
            users,
            "$" + new Intl.NumberFormat("en-US").format(importe)
          ]],
          startY: 45,
        });
        doc.setFontSize(10);
        doc.text(`Agradecemos tu pago antes del dia 5 del mes.`,15,70);
        doc.text(`Ayúdanos a continuar el servicio de calidad que nos caracteriza.`,15,75);


        doc.save(`Cargo.pdf`);

  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.usuarios_permisos ? (
        <div className="card container col-sm-11">
          <Row>
            <Col sm={8}>
              {user.usuarios_permisos ? (
                <Button
                  size="sm"
                  href="/CargosCreate"
                  className="btn btn-success"
                  id="botonListado"
                >
                  Crear Cargo
                </Button>
              ) : (
                <Button
                  size="sm"
                  disabled
                  className="btn btn-success"
                  id="botonListado"
                >
                  Crear Cargo
                </Button>
              )}
              {/* <Button
                        size="sm"
                        href="/MenuCargos"
                        className="btn btn-danger"
                        id="botonListado"
                      >
                        Regresar
                      </Button> */}
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="info"
                  onClick={toggleMail}
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Cargos</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>

          <h3 align="center">Listado Cargos</h3>

          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            {/* <Col md={1}>
                    <Button     
                    size="sm"   
                        className="btn ir" 
                        color="info"
                        onClick={jalaFechas}
                        >
                          Ir 
                        </Button>
                    </Col> */}
          </Row>

          <div className="row">
            <div className="col-md-6">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-6 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td></td>

              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedUser}
                  required
                  onChange={(e) => {
                    setSelectedUser(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {users
                    .sort((a, b) =>
                      a.nombre > b.nombre ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.nombre}</option>
                      );
                    })}
                </Input>
              </td>
            </tr>
            <tbody>
              {commentsData.map((c) => {
                total = total + c.importe;
                return (
                  <tr>
                    <td>{c.fecha}</td>
                    <td>{c.user}</td>
                    <td>
                      {"$" + new Intl.NumberFormat("en-US").format(c.importe)}
                    </td>
                    <td>
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  c.fecha,
                                  c.user,
                                  c.importe,
                                  c.numero
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button>
                        
                      </td>
                    {/* <td>
                      <Row>
                        {user.usuarios_permisos ? (
                          <Button
                            color="info"
                            id="Editar"
                            size="sm"
                            onClick={(e) => jalaInfo(c._id, c.user)}
                          >
                            <i class="far fa-edit"></i>
                          </Button>
                        ) : (
                          <Button color="info" id="Editar" size="sm" disabled>
                            <i class="far fa-edit"></i>
                          </Button>
                        )}
                      </Row>
                    </td> */}
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td className="negrita" align="center">
                  TOTAL
                </td>
                <td className="negrita">
                  {"$" + new Intl.NumberFormat("en-US").format(total)}
                </td>
                <td></td>
              </tr>
            </tbody>
          </Table>

          <Pagination
            total={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      ) : undefined}
      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar Cargo</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6}>
              <Label>User</Label>
              <Input type="text" value={usersEdit} disabled />
            </Col>
            <Col md={6}>
              <Label>Fecha</Label>
              <Input
                type="date"
                value={fecha}
                required
                onChange={(e) => {
                  setFecha(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label>Importe</Label>
              <Input
                className="col-sm-12"
                type="number"
                placeholder="Importe"
                value={importe}
                required
                onChange={(e) => {
                  setImporte(e.target.value);
                }}
              />
            </Col>
            <Col md={6}>
              <Label className="mr-sm-2">Descripcion</Label>
              <Input
                className="col-sm-12"
                type="text"
                placeholder="Descripcion"
                value={descripcion}
                required
                onChange={(e) => {
                  setDescripcion(e.target.value);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          {validaBoton ? (
            <Button className="btn btn-success" onClick={editCargo}>
              Registrar
            </Button>
          ) : (
            <Button className="btn btn-success" disabled>
              Registrar
            </Button>
          )}
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>Progreso</h4>
        </ModalHeader>
        <ModalBody>
          Cargando...
          <br />
          Por favor no cierre ni refresque su navegador.
          <br />
          <div className="divPadre2">
            <div className="divHijo2">
              <Spinner
                style={{ width: "3rem", height: "3rem", color: "#232B41" }}
              />
              <Spinner
                style={{ width: "3rem", height: "3rem", color: "#232B41" }}
              />
              <Spinner
                style={{ width: "3rem", height: "3rem", color: "#232B41" }}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
      {loader}
    </>
  );
}

export default ListadoCargos;
