import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Header from "../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
  FormGroup
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import moment from 'moment';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function AbonosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_USERS = process.env.REACT_APP_URL_USERS;
  const URL_ABONOS = process.env.REACT_APP_URL_ABONOS;
  const URL_CARGOS = process.env.REACT_APP_URL_CARGOS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");
  
  const [text, setText] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedUserNombre, setSelectedUserNombre] = useState("");
  const [fecha, setFecha] = useState(hoy);


  const [formaPago, setFormaPago] = useState("Efectivo");

  const [value, setValue] = useState("");
  const [validaBoton, setValidaBoton] = useState(true);
  const [concepto, setConcepto] = useState("Abono");
  const [total, setTotal] = useState(0);

  const [inputFields, setInputFields] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);



  useMemo(()=>{
    axios
    .get(URL_USERS, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allUsers = response.data;
      setUsers(allUsers);
    })
    .catch((err) => {
      console.log(err);
    });


    axios
    .get(`${URL_CARGOS}/user/${selectedUser}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allCargos = response.data
      let arrayTabla = allCargos
        .map((a) => {
          return {
            id: a._id,
            fecha: a.fecha,
            total_general: a.importe,
            saldo: a.saldo,
            abonos: a.abonos,
            nota: a.idCargo,
            importe: 0,
            user: a.user[0]._id,
            observaciones: "NA",
            movimiento:"Nota"
          };
        }).filter(function (el) {
          return el != null;
        });
        let dataFinal = Object.values(arrayTabla);
        setInputFields(dataFinal);
    })
    .catch((err) => {
      console.log(err);
    })


  }, [selectedUser]);


const handleChangeInputImporte = (id, event) => {
  const newInputFields = inputFields.map((i) => {
    if (id === i.id) {
      if(event.target.value <= i.saldo || event.target.value == ""){
        i[event.target.name] = parseFloat(event.target.value)
      }
      else{
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "El Pago excede el Saldo",
        });
      }
    }
    return i;
  });
  setInputFields(newInputFields);
  ActualizaTotales();
};



function ActualizaTotales(){
  let TEfe = inputFields.map((c) => parseFloat(c.importe));
  let TE = TEfe.reduce((t, total, index) => t + total, 0);

  setTotal(TE )
}  



function savePago() {
  Swal.fire({
    title: "Estas seguro?",
    text: "Se registrará el abono",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Enviar!",
  }).then((result) => {
    if (result.isConfirmed) {
      toggleProgreso();
      let totalMails = inputFields.length 
      inputFields.map((a) => {
        if (a.importe > 0) {
          axios
            .post(
              URL_ABONOS,
              {
                fecha,
                importe: a.importe,
                user: selectedUser,
                cargos: a.id,
                formaPago,
                concepto,
                comisionPagada:"No",
                observaciones: a.observaciones,
                aplicado: "No"
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem(
                    "app_token"
                  )}`,
                },
              }
            )
            .then((data) => {
              axios
              .patch(
                `${URL_CARGOS}/${a.id}`,
                {
                  saldo:a.saldo-a.importe,
                  abonos: a.abonos + a.importe
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              totalMails = totalMails - 1;
              if (totalMails == 0) {
                Swal.fire("Good job!", "Creado con exito", "success");
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                // footer: `${error.response.data}`,
              });
              console.log(error);
            });
        } else {
          totalMails = totalMails - 1;
        }
      })


    }
  });
}

const handleChangeInput = (id, event) => {
  const newInputFields = inputFields.map((i) => {
    if (id === i.id) {
      i[event.target.name] = event.target.value;
    }
    return i;
  });
  setInputFields(newInputFields);
};


const options = users.map((option) => {
    const junta = option.nombre;
    const firstLetter = option.nombre[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

// function clear(){
//   setInputFields([])
//   setValue("")
//   setSelectedUser("")
//   setSelectedUserNombre("")
//   setTotal(0)
//   setFormaPago("Efectivo")
//   setConcepto("Abono")
// }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_usuarios  ?(
        <div className="card container col-sm-11">
          <h3 align="center">Captura un Pago</h3>
          <Form>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Row>
              <Col md={2}>
                  <Label>Fecha</Label>
                  <Input
                    type="date"
                    value={fecha}
                    required
                    onChange={(e) => {
                      setFecha(e.target.value);
                    }}
                  />
                </Col>
                <Col md={3}>
                <Label className="mr-sm-2">User</Label>
                <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedUser) => {
                      setValue(selectedUser)
                      setSelectedUser(selectedUser._id)
                      setSelectedUserNombre(selectedUser.nombre)
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo} {option.nombre}
                      </React.Fragment>
                    )}
                  />
                </Col>
                <Col md={2}>
                    <Label className="mr-sm-2">Concepto</Label>
                    <Input
                      type="select"
                      value={concepto}
                      onChange={(e) => {
                        setConcepto(e.target.value);
                      }}
                    >
                      <option value="">Selecciona un Concepto</option>
                      <option value="Abono">Abono</option>
                      <option value="Bonificacion">Bonificacion</option>
                    </Input>
                  </Col>

                <Col md={2}>
                    <Label className="mr-sm-2">Forma de Pago</Label>
                    <Input
                      type="select"
                      value={formaPago}
                      onChange={(e) => {
                        setFormaPago(e.target.value);
                      }}
                    >
                      <option value="">Selecciona una Forma de Pago</option>
                      <option value="Efectivo">Efectivo</option>
                      <option value="Transfer">Transfer</option>
                      <option value="TDC">TDC</option>
                      <option value="Bonificacion">Bonificacion</option>
                    </Input>
                  </Col>
                
               
            
                <Col md={3}>
                <h4 align="right">
                              Total Pago{"  "}
                              <Badge id="Total" color="danger" className="BadgeSize badge-pill">
                                {"$" + new Intl.NumberFormat("en-US").format(total)}
                              </Badge>
                            </h4>
                </Col>
              </Row>
              <br />
             <Row>
                {/* Tabla Cargos */}
              <br />
              <br />
              <Table striped borderless>
                <thead>
                  <tr>
                  <th>Fecha</th>
                  <th>Movimiento</th>
                  <th>Nota</th>
                  <th>Total</th>
                  <th>Abonos</th>
                  <th>Saldo</th>
                  <th>Importe</th>
                  <th>Observaciones</th>
                  </tr>
                </thead>
                <tbody>
                  {inputFields.map((a) => (
                      // <div key={a.id}>
                            <tr>
                            <td>{a.fecha}</td>
                            <td>{a.movimiento}</td>
                            <td>{a.nota}</td>
                            <td>{"$" + new Intl.NumberFormat("en-US").format(a.total_general)}</td>
                            <td>{"$" + new Intl.NumberFormat("en-US").format(a.abonos)}</td>
                            <td>{"$" + new Intl.NumberFormat("en-US").format(a.saldo)}</td>
                            <td>
                              <Input
                              bsSize="sm"
                                type="number" 
                                name="importe"
                                value={a.importe}
                                key={a.id}
                                onChange ={(e)=>{handleChangeInputImporte(a.id, e)
                                }}/>
                            </td>
                            <td>
                              <Input
                              bsSize="sm"
                                type="text" 
                                name="observaciones"
                                value={a.observaciones}
                                key={a.id}
                                onChange ={(e)=>{handleChangeInput(a.id, e)
                                }}/>
                            </td>
                            </tr>
                          // </div>
                  ))}

                </tbody>
                <br />
              </Table>
          

            {/* Termina tabla cargos */}

             </Row>
            </FormGroup>
            <br />
            <Row>
            <Button
              className="btn btn-success"
              onClick={savePago}
            > Registrar
            </Button>

              <Button
                href="/ListadoAbonos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Row>
          </Form>
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
                  <ModalHeader toggle={toggleProgreso}>
                    <h4>Progreso</h4>
                  </ModalHeader>
                  <ModalBody>
                    Estamos enviando los correos, este proceso puede tardar
                    varios minutos.
                    <br />
                    Por favor no cierre ni refresque su navegador.
                    <br />
                    <div className="divPadre2">
                      <div className="divHijo2">
                        <Spinner
                          style={{
                            width: "3rem",
                            height: "3rem",
                            color: "#232B41",
                          }}
                        />
                        <Spinner
                          style={{
                            width: "3rem",
                            height: "3rem",
                            color: "#232B41",
                          }}
                        />
                        <Spinner
                          style={{
                            width: "3rem",
                            height: "3rem",
                            color: "#232B41",
                          }}
                        />
                      </div>
                    </div>
                  </ModalBody>
                </Modal>

               
        </div>
      ): undefined } 
      
    </>
  );
}

export default AbonosCreate;
