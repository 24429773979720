import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Signup from "./Signup";
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Col,
  Badge
} from "reactstrap";
import Header from "../Header/Header";
import { TableHeader, Pagination, Search } from "../../DataTable";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import Baja from "../../views/Baja";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function ListadoUsuarios() {
  const { user } = useContext(AuthContext);
  const URL_USERS = process.env.REACT_APP_URL_USERS;
  const URL_LISTA_USERS = process.env.REACT_APP_URL_LISTA_USERS;
  const URL_CONFIGS = process.env.REACT_APP_URL_CONFIGS;

  const [config, setConfig] = useState("");
  const [idConfig, setIdConfig] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [activo, setActivo] = useState("");
  const ITEMS_PER_PAGE = 50;

  // const URL_PLANTELES = process.env.REACT_APP_URL_PLANTELES;
  // const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const [idEdit, setIdEdit] = useState();
  const [nombre, setNombre] = useState();
  const [email, setEmail] = useState();

  const [idPermisos, setIdPermisos] = useState();

  const [menu_usuarios, setMenuUsuarios] = useState();
  const [usuarios_create, setUsuariosCreate] = useState();
  const [usuarios_permisos, setUsuariosPermisos] = useState();

  const [menu_logs, setMenuLogs] = useState();
  const [menu_admin, setMenuAdmin] = useState();

  const [emailSis, setEmailSis] = useState("admin@sistemify.com");

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [text, setText] = useState(false);

  const [modalPermisos, setModalPermisos] = useState(false);
  const togglePermisos = () => setModalPermisos(!modalPermisos);

  useEffect(() => {
    axios
      .get(URL_LISTA_USERS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allUsuarios = response.data;
        let arrayTabla = allUsuarios
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              is_active: a.is_active,
              menu_usuarios: a.menu_usuarios,
              usuarios_create: a.usuarios_create,
              usuarios_permisos: a.usuarios_permisos,
              menu_logs: a.menu_logs,
              menu_admin: a.menu_admin,
              nombre: a.nombre,
              email: a.email,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
    .get(URL_CONFIGS, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((res) => {
      const allConfig = res.data;
      setConfig(allConfig[0].is_active);
      setIdConfig(allConfig[0]._id)
    })
    .catch((err) => {
      console.log(err);
    });
  }, []);


  const headers = [
    { name: "Activo", field: "is_active", sortable: true },
    { name: "Nombre", field: "nombre", sortable: true },
    { name: "Email", field: "email", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.is_active.toLowerCase().includes(search.toLowerCase()) ||
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.email.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.is_active.includes(activo)
      );
    }

    if (emailSis) {
      computedComments = computedComments.filter(
        (e) => !e.email.includes(emailSis)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [comments, currentPage, search, sorting, activo]);

  function Edit(idEdit, nombre,  email) {
    setIdEdit(idEdit);
    setNombre(nombre);
    setEmail(email);
    toggle();
  }

  function editUsuario(event) {
    event.preventDefault();
    axios
      .patch(
        `${URL_USERS}/${idEdit}`,
        {
          nombre,
          email,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggle();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function Permisos(
    id,
    nombre,
    menu_usuarios,
    usuarios_create,
    usuarios_permisos,
    menu_logs,
    menu_admin,
  ) {
    setIdPermisos(id);
    setNombre(nombre);
    setMenuUsuarios(menu_usuarios);
    setUsuariosCreate(usuarios_create);
    setUsuariosPermisos(usuarios_permisos);
    setMenuLogs(menu_logs);
    setMenuAdmin(menu_admin);
    togglePermisos();
  }

  function permisosUsuario(event) {
    event.preventDefault();
    axios
      .patch(
        `${URL_USERS}/${idPermisos}`,
        {
          menu_usuarios,
          usuarios_create,
          usuarios_permisos,
          menu_logs,
          menu_admin,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        togglePermisos();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  async function Actualizar() {
    Swal.fire({
      title: "Estas seguro?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si!",
    }).then((result) => {
      if (result.isConfirmed) {
    let allAct = config == "Si" ? "No" : "Si";
    axios
      .patch(`${URL_CONFIGS}/${idConfig}`,
        {
          is_active: allAct
        },
        {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
    }
  });
}

  return (
    <>
      <Header />
      <br />
      {user.menu_usuarios ? (
        <div className="row">
          {
            <div className="col-8">
              <div className="card">
                <div className="card-body">
                <Row>
                  <Col md={10}>
                  </Col>
                  <Col md={2}>
                    <h4 align="right">
                      <Badge
                        color={config == "Si" ? "success" : "danger"}
                        className="BadgeSize badge-pill"
                        onClick={Actualizar}
                      >
                        {config == "Si" ? "Activo" : "No Activo"}
                      
                      </Badge>
                    </h4>
                  </Col>
                </Row> 
                  <h3 align="center">Usuarios</h3>
                  <div className="row">
                    <div className="col-md-6">
                      <Pagination
                        total={totalItems}
                        itemsPerPage={ITEMS_PER_PAGE}
                        currentPage={currentPage}
                        onPageChange={(page) => setCurrentPage(page)}
                      />
                    </div>
                    <div className="col-md-6 d-flex flex-row-reverse">
                      <Search
                        onSearch={(value) => {
                          setSearch(value);
                          setCurrentPage(1);
                        }}
                      />
                    </div>
                  </div>
                  <Table
                    size="sm"
                    striped
                    borderless
                    className="table-responsive-xl"
                  >
                    <TableHeader
                      headers={headers}
                      onSorting={(field, order) => setSorting({ field, order })}
                    />
                    <tbody>
                      {commentsData.map((p) => {
                        // if (p.menu_edoCta_Fam == false && p.menu_edoCta_Don == false && p.email != "admin@sistemify.com") {
                        return (
                          <tr>
                            <td>{p.is_active}</td>
                            <td>{p.nombre}</td>
                            <td>{p.email}</td>
                            <td>
                              {user.usuarios_create ? (
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  onClick={(e) => {
                                    Edit(p._id, p.nombre, p.email);
                                  }}
                                >
                                  <i class="far fa-edit"></i>
                                </Button>
                              ) : (
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  disabled
                                >
                                  <i class="far fa-edit"></i>
                                </Button>
                              )}
                              {user.usuarios_permisos &&
                              user.is_active == "Si" ? (
                                <Button
                                  color="success"
                                  id="Permisos"
                                  size="sm"
                                  onClick={(e) => {
                                    Permisos(
                                      p._id,
                                      p.nombre,
                                      p.menu_usuarios,
                                      p.usuarios_create,
                                      p.usuarios_permisos,
                                      p.menu_logs,
                                      p.menu_admin,
                                    );
                                  }}
                                >
                                  <i class="fas fa-key"></i>{" "}
                                </Button>
                              ) : (
                                <Button
                                  color="success"
                                  id="Permisos"
                                  size="sm"
                                  disabled
                                >
                                  <i class="fas fa-key"></i>{" "}
                                </Button>
                              )}
                              {user.usuarios_create ? (
                                <Baja
                                  idStatus={p._id}
                                  is_active={p.is_active}
                                  URL_BAJA={process.env.REACT_APP_URL_USERS}
                                />
                              ) : undefined}
                            </td>
                          </tr>
                        );
                        // }
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          }
          <div className="col-4">
            <div className="card">
              <div className="card-body">
                <Signup />
              </div>
            </div>
          </div>
        </div>
      ) : undefined}

      <Modal size="sm" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h4>
            Editar Usuario {nombre}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Nombre</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={nombre}
            required
            onChange={(e) => {
              setNombre(e.target.value);
            }}
          />
          <Label className="mr-sm-2">Email</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={email}
            required
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editUsuario}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      {/* //Permisos */}
      <Modal size="xl" isOpen={modalPermisos} toggle={togglePermisos}>
        <ModalHeader toggle={togglePermisos}>
          <h4>
            Permisos de {nombre}
          </h4>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <Row>
            

              <Col md={3}>
                <h5>ADMIN</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuAdmin(event.currentTarget.checked)
                  }
                  checked={menu_admin}
                />
                <h5 className="subMenuTitulo">Menu Admin</h5>
              </Col>

           
            </Row>
            <br />
            <Row>

              <Col md={3}>
                <h5>USUARIOS</h5>
                <Input
                  type="checkbox"
                  onChange={(event) =>
                    setMenuUsuarios(event.currentTarget.checked)
                  }
                  checked={menu_usuarios}
                />
                <h5 className="subMenuTitulo">Menu Usuarios</h5>
              </Col>

              <Col md={3}>
                <h5>LOGS</h5>
                <Input
                  type="checkbox"
                  onChange={(event) => setMenuLogs(event.currentTarget.checked)}
                  checked={menu_logs}
                />
                <h5 className="subMenuTitulo">Menu Logs</h5>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="container">
            <Button color="success" onClick={permisosUsuario}>
              Guardar
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      {loader}
    </>
  );
}

export default ListadoUsuarios;
